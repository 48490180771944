import * as React from 'react';
import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';
import UserContext from '../../UserContext';
import RefreshIcon from '@mui/icons-material/Refresh';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function PhonePop({mobavail}) {

    const [loggedInUserDetails] = React.useContext(UserContext);
    const [open, setOpen] = React.useState(false);
    const [phone, setPhone] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [isLoading, setIsLoading] = React.useState(false);
    const [numEntered, setNumEntered] = React.useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const [wrongOtp, setWrongOtp] = useState(false);
    const [helperText, setHelperText] = useState('');
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const sanitizePhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/\D/g, '').slice(-10);
      };
    
      const handleGetOTP = async () => {
        if (!phone || phone.length < 10) {
          alert('Please enter a valid phone number');
          return;
        }
        setIsResendDisabled(true); // Disable Resend OTP button
        setResendTimer(30); // Start 30-second timer
        setIsLoading(true);
        setNumEntered(true);
        const sanitizedPhone = sanitizePhoneNumber(phone);
    
        try {
          const response = await axios.post(
            "https://collectorbabu.com/api/user/generate_mobile_otp",
            {
              user_id: loggedInUserDetails.user_id,
              mobile_number: phone
            },
            {
            headers: {
                token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
                "Content-Type": "application/json",
              },
            }
          );
    
        //   if (response.data.success) {
        //     console.log(response)
        //   } else {
        //     alert(response.data.message || 'Failed to generate OTP');
        //   }
        } catch (error) {
          console.error("Error fetching OTP:", error);
        //   alert('Failed to generate OTP. Please try again.');
        } finally {
          setIsLoading(false);
        }
      };
      useEffect(() => {
        let timer;
        if (resendTimer > 0) {
          timer = setInterval(() => {
            setResendTimer((prev) => {
              if (prev <= 1) {
                clearInterval(timer);
                setIsResendDisabled(false); // Enable Resend OTP button
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
        }
        return () => clearInterval(timer); // Cleanup on unmount
      }, [resendTimer]);

      const handleOtpChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); 
        if (value.length <= 4) {
          setOtp(value); 
        }
      };
    
      const handleSubmitOTP = async() => {
        if (otp.length !== 4) {
            setWrongOtp(true);
            setHelperText('please enter 4 digit OTP');
          return;
        }
        setWrongOtp(false);
        // alert(`Submitted OTP: ${otp}`);
          try {
            console.log('otp submitted' + otp);
            const response = await axios.post(
              "https://collectorbabu.com/api/user/verify_mobile_otp",
              {
                user_id: loggedInUserDetails.user_id,
                mobile_number: phone,
                otp: otp
              },
              {
              headers: {
                  token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
                  "Content-Type": "application/json",
                },
              }
            );
              handleClose();
          } catch (error) {
            console.error("Error verifying OTP:", error);
            if (
            error.response &&
            error.response.data &&
            error.response.data.detail === "Invalid OTP"
            ) {
            setWrongOtp(true);
            setHelperText('Invalid OTP');
            }else if(
                error.response &&
                error.response.data &&
                error.response.data.detail === "OTP expired"
            ){
                setWrongOtp(true);
                setHelperText('OTP expired');
            } else {
            alert("An unexpected error occurred. Please try again.");
            }
          } finally {
            setIsLoading(false);
          }
      };
      const handleResendOTP = () => {
        // Simulate Resend OTP request
        // alert('OTP Resent!');
        setIsResendDisabled(true); // Disable Resend OTP button again
        setResendTimer(30); // Reset 30-second timer
        handleGetOTP();
      };

      React.useEffect(() => {
        console.log("phonePopup enabled -> " + !mobavail);
        if (mobavail === false) {
          setOpen(true); 
        }
      }, [mobavail]); 
    
  return (
    <>
      <Dialog
        className='w-full'
        open={open}
        onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          aria-labelledby="responsive-dialog-title"
          BackdropProps={{ style: { pointerEvents: 'none' } }}
      >
        <div className='flex flex-col justify-center items-center w-full my-auto md:p-[70px]'>
            <div className='w-full flex justify-end px-2'>
                <button onClick={handleClose}>
                    <CloseIcon />
                </button>
            </div>
              <h2 className="mx-auto text-gray-600 font-semibold">Complete your profile!</h2>
              <DialogTitle className='text-center'>A mobile number keeps you closer to us.</DialogTitle>
                <DialogContent className='w-[80%]'>
                <PhoneInput
                className="bg-white rounded-md my-1"
                    country={'in'}
                    onlyCountries={['in']}
                    inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true
                    }}
                    value={phone}
                    disableCountryCode={true}
                    enableAreaCodes={false}
                    onChange={(value) => {
                        // Only keep digits and limit to 10 characters
                        const numbersOnly = value.replace(/\D/g, '');
                        const lastTenDigits = numbersOnly.slice(0, 10); // Changed from slice(-10) to slice(0, 10)
                        setPhone(lastTenDigits);
                    }}
                    inputStyle={{ width: '100%' }}
                    specialLabel=""
                    />
                </DialogContent>

            <DialogActions className='w-[80%]'>
            {!numEntered ? (
                <div className='w-full'>
                <Button 
                variant="contained" 
                style={{ 
                width: "100%",
                marginTop: "20px",
                backgroundColor: "#23b279",
                padding: "10px 16px",
                borderRadius: "8px",
                border: "none",
                fontFamily: "Manrope",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "1",
                textTransform: "none"  // This removes Bootstrap's default text transformation
                }}
                disabled={isLoading} 
                autoFocus
                onClick={handleGetOTP}
            >
                <span style={{ color: "white" }}>
                {isLoading ? 'Sending...' : 'Get OTP'}
                </span>
            </Button>
            </div>
            ) : (
                <>
                <div className='flex flex-col w-full justify-center items-center'>
                {/* <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value.replace(/\D/g, '').slice(0, 4))}
                placeholder="Enter OTP"
                maxLength={4}
                style={{
                    width: '100%',
                    padding: '8px',
                    fontSize: '16px',
                    marginTop: '10px',
                    borderRadius: '4px',
                    border: '1px solid #ccc'
                }}
                /> */}
                <TextField
        error={wrongOtp} // Display error state if OTP is invalid
        id="outlined-error-helper-text"
        label="OTP"
        value={otp}
        onChange={handleOtpChange}
        helperText={wrongOtp ? helperText : ''}
        inputProps={{
          maxLength: 4, // Restrict input length to 4
          pattern: '\\d*', // Allow only numeric input
        }}
        style={{
          width: '100%',
          marginTop: '10px',
        }}
      />
                <div className='flex flex-col gap-1 w-full'>
                <Button 
                    variant="contained" 
                    style={{ 
                        width: "100%",
                        marginTop: "20px",
                        backgroundColor: "#23b279",
                        padding: "10px 16px",
                        borderRadius: "8px",
                        border: "none",
                        fontFamily: "Manrope",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "1",
                        textTransform: "none"  // This removes Bootstrap's default text transformation
                    }}
                    onClick={handleSubmitOTP}
                    >
                    <span style={{ color: "white" }}>
                    {isLoading ? 'Sending...' : 'Submit OTP'}
                    </span>
                </Button>
                <button
            className={`text-blue-400 border-none w-fit mx-auto ${
              isResendDisabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleResendOTP}
            disabled={isResendDisabled}
          >
            <RefreshIcon /> {isResendDisabled ? `Resend OTP (${resendTimer}s)` : 'Resend OTP'}
          </button>
                </div>
                </div>
                </>
            )}
            </DialogActions>
        </div>
      </Dialog>
    </>
  )
}

export default PhonePop