import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../UserContext";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import LoginPrompt from "../LoginPrompt";
import { useMediaQuery, createTheme } from '@mui/material';
import LeftPane from "../LeftPane/LeftPane";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import OnScreenMenu from '../OnScreenMenu';
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import { IoRefresh } from "react-icons/io5";
import axios from "axios";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { CiImageOn } from "react-icons/ci";
import { FaRegFilePdf } from "react-icons/fa";
import { FilePlus } from "@phosphor-icons/react";
import { GoLock } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { XCircle } from "@phosphor-icons/react";
import {CircleLoader} from "react-spinners"
import { isLoggedIn, mainsAnswerReview } from "../../utils";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import "./index.css";
import { Helmet } from "react-helmet";
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step5 from './step5';
import {ConsolePage} from '../GPTAIInterview/pages/ConsolePage';
import {DAFOverviewContext} from "../../DAFOverviewContext";


const AIMockInterviewContainer =()=>{
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const theme = createTheme();
  const gaEventTracker = useAnalyticsEventTracker("upsc-mains-answer-evaluation");
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    wrapperIsLoggedIn();
    gaEventTracker(`${loggedInUserDetails.name ? loggedInUserDetails.name : "logged-out user"}/dashboard`);
  }, []);

  
  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    } else {
    //   setOpenLoginPrompt(true);
    }
  }; 

  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  // });

  return (
    <>
      {isMobile ? (
        <>
        <Helmet>
          <title>
            UPSC IAS Online Mock Interview - AI-Powered Mock Interviews for UPSC
            Aspirants
          </title>
          <meta
            name="description"
            content="Prepare for your UPSC IAS interview with our AI-powered online mock interview platform. Get real-time feedback and insights to ace the Civil Services interview."
          />
          <meta
            name="keywords"
            content="UPSC, IAS, mock interview, online mock interview, IAS interview, AI mock interview, UPSC interview preparation, Civil Services, Indian Administrative Service, SIM, SIM Initiative,Self Interview Mocks"
          />
          <meta
            property="og:title"
            content="UPSC IAS Online Mock Interview - AI-Powered Mock Interviews for UPSC Aspirants"
          />
          <meta
            property="og:description"
            content="Prepare for your UPSC IAS interview with our AI-powered online mock interview platform. Get real-time feedback and insights to ace the Civil Services interview."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://collectorbabu.com/upsc-ias-mock-interview"
          />
          <meta
            property="og:image"
            content="https://collectorbabu.com/images/mainLogo.png"
          />
          <meta property="og:site_name" content="Collector Babu" />
          <meta
            name="twitter:card"
            content="https://collectorbabu.com/images/mainLogo.png"
          />
          <meta
            name="twitter:title"
            content="UPSC IAS Online Mock Interview - AI-Powered Mock Interviews for UPSC Aspirants"
          />
          <meta
            name="twitter:description"
            content="Prepare for your UPSC IAS interview with our AI-powered online mock interview platform. Get real-time feedback and insights to ace the Civil Services interview."
          />
          <meta
            name="twitter:image"
            content="https://collectorbabu.com/images/mainLogo.png"
          />
          <link
            rel="canonical"
            href="https://collectorbabu.com/upsc-ias-mock-interview"
          />
        </Helmet>
        <LeftPane Component={AIMockInterview} />
        <OnScreenMenu/>
    <Footer/>
        </>
      ):(  
        <>
        <Helmet>
          <title>
            UPSC IAS Online Mock Interview - AI-Powered Mock Interviews for UPSC
            Aspirants
          </title>
          <meta
            name="description"
            content="Prepare for your UPSC IAS interview with our AI-powered online mock interview platform. Get real-time feedback and insights to ace the Civil Services interview."
          />
          <meta
            name="keywords"
            content="UPSC, IAS, mock interview, online mock interview, IAS interview, AI mock interview, UPSC interview preparation, Civil Services, Indian Administrative Service, SIM, SIM Initiative,Self Interview Mocks"
          />
          <meta
            property="og:title"
            content="UPSC IAS Online Mock Interview - AI-Powered Mock Interviews for UPSC Aspirants"
          />
          <meta
            property="og:description"
            content="Prepare for your UPSC IAS interview with our AI-powered online mock interview platform. Get real-time feedback and insights to ace the Civil Services interview."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://collectorbabu.com/upsc-ias-mock-interview"
          />
          <meta
            property="og:image"
            content="https://collectorbabu.com/images/mainLogo.png"
          />
          <meta property="og:site_name" content="Collector Babu" />
          <meta
            name="twitter:card"
            content="https://collectorbabu.com/images/mainLogo.png"
          />
          <meta
            name="twitter:title"
            content="UPSC IAS Online Mock Interview - AI-Powered Mock Interviews for UPSC Aspirants"
          />
          <meta
            name="twitter:description"
            content="Prepare for your UPSC IAS interview with our AI-powered online mock interview platform. Get real-time feedback and insights to ace the Civil Services interview."
          />
          <meta
            name="twitter:image"
            content="https://collectorbabu.com/images/mainLogo.png"
          />
          <link
            rel="canonical"
            href="https://collectorbabu.com/upsc-ias-mock-interview"
          />
        </Helmet>
        <Box
      sx={{
        display: 'flex',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100%',
      }}
    >

        <Navbar  />
        <Box
        sx={{
          height: '100vh',
          backgroundSize: 'cover',
        }}
      >
        <Sidebar />

        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <div style={{ marginTop: '75px',marginLeft:"90px", width:"93vw" }}> 
            <AIMockInterview />
        <Footer />
          </div>
        </Box>
        <OnScreenMenu/>
        </Box>
        </Box>
        </>
      )}
    </>
  );
}

const AIMockInterview = () => {
  const {activeStep, setActiveStep} = useContext(DAFOverviewContext);
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  // console.log("loggedInUserDetails", loggedInUserDetails);
  const [images, setImages] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [timer, setTimer] = useState(59);
  const [openLoginPrompt, setOpenLoginPrompt] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const renderStepComponent = () => {
    switch (activeStep) {
      case 1:
        return <Step1 activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 2:
        return <Step2 activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 3:
        return <Step3 activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 4:
        return <ConsolePage activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 5:
        return <Step5 activeStep={activeStep} setActiveStep={setActiveStep} />;  
        default:
        return <Step1 activeStep={activeStep} setActiveStep={setActiveStep} />;
    }
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleLoginPromptOpen = () => {
    setOpenLoginPrompt(true);
  };

  const handleLoginPromptClose = () => {
    setOpenLoginPrompt(false);
  };

  

  return (
    <>
      {renderStepComponent()}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <LoginPrompt
        openLoginPrompt={openLoginPrompt}
        handleLoginPromptClose={handleLoginPromptClose}
      />
    </>
  );
};

export default AIMockInterviewContainer;
