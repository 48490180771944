import { useEffect, useState, useContext } from "react";
import * as React from "react";
import "./LeftPane.css";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import UserContext from "../../UserContext";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import BottomNavbar from '../BottomNavbar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import premiumIcon from '../Navbar/premiumIcon.png';
import {
  Avatar,
  DialogActions,
  DialogContent, 
  Paper,
  Tooltip,
} from "@mui/material";
import CalendarContainer from "../CalendarContainer/CalendarContainer";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import HeaderBanner from "../HeaderBanner/HeaderBanner";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { Container } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import SidebarContext from "../../sidebarContext";
import NavigationIcon from "@mui/icons-material/Navigation";
import MoreIcon from "@mui/icons-material/MoreVert";
import Fab from "@mui/material/Fab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import { isBrowser, isMobile } from "react-device-detect";
import SpeedIcon from "@mui/icons-material/Speed";
import BannerContext from "../../bannerContext";
import { useParams } from "react-router-dom";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ArticleIcon from '@mui/icons-material/Article';
import DashboardIcon from "@mui/icons-material/Dashboard";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import GradingRoundedIcon from "@mui/icons-material/GradingRounded";
import { isIOS } from "react-device-detect";
import { pay, paymentStatus } from "../../utils";
import _ from "lodash";
import PaymentSnackbar from "../Payment/PaymentSnackbar";
import BootstrapDialogTitle from "../Modals/BootstrapDialogTitle";
import BootstrapDialog from "../Modals/Dialog";
import PaymentDialogParent from "../Payment/PaymentModal";
import BookIcon from "@mui/icons-material/Book";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import new_icon from "./new_icon.png";
import trending_icon from "./trending_icon.png";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import StyleIcon from '@mui/icons-material/Style';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'rgba(254, 254, 254, 1)',
  boxShadow: '0px 9px 12px rgba(0, 0, 0, 0.04)',
  borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
  // maxWidth: '393px',
  width: '100%',
  position: 'relative',
  minWidth: '100%',
}));

const LogoImage = styled('img')({
  aspectRatio: '3.85',
  objectFit: 'contain',
  objectPosition: 'center',
  width: '100px',
  alignSelf: 'stretch',
  margin: 'auto 0',
  height: '30px',
});

const DashboardButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(228, 255, 224, 1)',
  color: 'rgba(31, 91, 67, 1)',
  borderRadius: '6px',
  padding: '4px 10px',
  minHeight: '30px',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 2,
  border: '1px solid rgba(31, 91, 67, 0.1)',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(228, 255, 224, 0.9)'
  }
}));
const BuyButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#20B486', // Solid background color
  //  background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #2cde97`,
  color: 'white',
  borderRadius: '6px',
  padding: '4px ',
  minHeight: '30px',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: 2,
  border: '1px solid rgba(31, 91, 67, 0.1)',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(228, 255, 224, 0.9)'
  }
}));

const UserAvatar = styled(Avatar)({
  width: 30,
  height: 30
});

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '23px',
  padding: '12px 16px',
  width: '100%'
});

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={100}
        style={{ color: "lightgray" }}
      />
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar
          src={props.loggedinuserdetails.picture}
          sx={{ width: 31, height: 31 }}
        />
        {/* <img class="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500" src={props.loggedInUserDetails.picture} alt="Bordered avatar"></img> */}
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const drawerWidth = 220;
const selectedIconColor = "#0f6fff";
const selectedTabColor = "#0288d1";
const unselectedIconColor = "#616161";

const sidebarIconMap = new Map();
sidebarIconMap.set("Calendar", <CalendarTodayIcon />);
sidebarIconMap.set("Saved for Later", <BookmarkBorderIcon />);
sidebarIconMap.set("Add Content", <AddIcon />);
sidebarIconMap.set("PYQ Explorer", <MenuBookIcon />);
sidebarIconMap.set("Home", <HomeIcon />);
// sidebarIconMap.set("PYQ Interview", <PsychologyIcon />);
sidebarIconMap.set("Mock Interview", <CoPresentIcon />);
sidebarIconMap.set("The Desi Explainer", <ArticleIcon />);
sidebarIconMap.set("360 Reader", <NewspaperIcon />);
sidebarIconMap.set("AI Answer Review", <GradingRoundedIcon />);
sidebarIconMap.set("Notes Zero", <BookIcon />);
sidebarIconMap.set("Mains 2024", <ContentPasteIcon />);

const LeftPane = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  // const [sidebarData, setSidebarData] = useContext(SidebarContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorBottomNavigationMoreEl, setAnchorBottomNavigationMoreEl] =
    useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [showSidebarInMobile, setShowSidebarInMobile] = useState(false);
  const [bannerData, setBannerData] = useContext(BannerContext);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentModalNestedOpen, setPaymentModalNestedOpen] = useState(false);
  const [payData, setPayData] = useState({});
  const [loaderPayModal, setLoaderPayModal] = useState(false);
  const [intervalIds, setIntervalIds] = useState([]);
  const [showPaymentSnackbar, setShowPaymentSnackbar] = useState(false);
  const [openLoginPrompt, setLoginPrompt] = useState(false);
  const [snackbarStatusMessage, setSnackbarStatusMessage] = useState("");
  const [showUPISelectScreen, setShowUPISelectScreen] = useState(false);
  const [selectedUPIApp, setSelectedUPIApp] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isBottomNavigationMoreOpen = Boolean(anchorBottomNavigationMoreEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { Component } = props;
  let location = useLocation();
  let navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove("google_info");
    Cookies.remove("user_name");
    Cookies.remove("user_id");
    Cookies.remove("email");
    setLoggedInUserDetails(Object.assign({}));
    navigate("/login");
  };

  const handleRemoveBanner = () => {
    setBannerData({
      showBanner: "false",
    });

    localStorage.setItem("showBanner", false);
  };

  const handleClosePaymentSnackbar = () => {
    setShowPaymentSnackbar(false);
  };

  const handleOpenUPISelectScreen = () => {
    setShowUPISelectScreen(true);
  };

  const handleCloseUPISelectScreen = () => {
    setShowUPISelectScreen(false);
    setSelectedUPIApp(null);
  };

  const handleOpenPaymentSnackbar = () => {
    setShowPaymentSnackbar(true);
  };

  const handleLoginPromptClose = () => {
    setLoginPrompt(false);
  };

  const handleLoginPromptOpen = () => {
    setLoginPrompt(true);
  };

  const handleDrawerIconNavigate = (index) => (event) => {
    let navigateTo = event.currentTarget.dataset.text
      .split(" ")
      .join("")
      .toLowerCase();
    if (navigateTo === "mockinterview") {
      navigateTo = "upsc-ias-mock-interview";
    } else if (navigateTo === "pyqfasttrack") {
      navigateTo = "pyq-fast-track";
    } else if (navigateTo === "360reader") {
      navigateTo = "360-reader";
    } else if (navigateTo === "aianswerreview") {
      navigateTo = "upsc-mains-answer-evaluation";
    } else if (navigateTo === "noteszero") {
      navigateTo = "upsc-notes-zero";
    } else if (navigateTo === "mains2023") {
      navigateTo = "upsc-mains-2023-essay-gs-questions-model-answers";
    }

    navigate("/" + navigateTo, { replace: true });
    // setSidebarData({
    //   selectedIndex: Number(event.currentTarget.dataset.index),
    // });
    setShowSidebarInMobile(false);
  };

  const handleProfileMenuClick = (event) => {
    navigate("/profile");
    handleMenuClose();
  };
  const handlePlanMenuClick = (event) => {
    navigate("/pricing");
    handleMenuClose();
  };

  const clearPaymentIntervalIds = (timeoutIDs) => {
    for (let id of timeoutIDs) {
      clearTimeout(id);
    }
  };

  const handlePaymentModalClose = () => {
    // setIntervalIds([]);
    setPaymentModalOpen(false);
  };

  const handleOpenNestedPaymentModal = (event) => {
    setPaymentModalNestedOpen(true);
  };

  const handleCloseNestedPaymentModal = (event) => {
    for (let intervalId of intervalIds) {
      clearInterval(intervalId);
    }
    setPaymentModalNestedOpen(false);
    handlePaymentModalClose();
    handleCloseUPISelectScreen();
  };

  const clearPayIntervals = () => {
    for (let intervalId of intervalIds) {
      clearInterval(intervalId);
    }
  };

  const handleCloseNestedPaymentModalOnly = (event) => {
    setPaymentModalNestedOpen(false);
  };

  const handleLoginButtonPrompt = () => {
    navigate("/login", { state: { from: window.location.pathname } });
  };
  // call this when the modal opens so
  // this initiates the payment and shows the image
  const reconcialationPaymentStatus = (merchantTransactionId) => {
    // generate all the times to hit payment status api

    // The first status check at 20-25 seconds post transaction start
    let times = [20000];

    // Every 3 seconds once for the next 30 seconds
    for (let i = 0; i < 10; i++) {
      times.push(times[i] + 3000);
    }
    // Every 6 seconds once for the next 60 seconds
    for (let i = 10; i < 20; i++) {
      times.push(times[i] + 6000);
    }

    // // Every 10 seconds for the next 60 seconds
    for (let i = 20; i < 26; i++) {
      times.push(times[i] + 10000);
    }

    //  Every 30 seconds for the next 60 seconds
    for (let i = 26; i < 28; i++) {
      times.push(times[i] + 30000);
    }

    // Every 1 min until timeout (15 mins)
    for (let i = 28; i < 43; i++) {
      times.push(times[i] + 60000);
    }

    let timeoutIDs = [];
    for (let time of times) {
      let timeoutID = setTimeout(
        async (merchantTransactionId, loggedInUserDetails) => {
          try {
            let status = await paymentStatus(
              merchantTransactionId,
              loggedInUserDetails
            );
            if (status.code === "PAYMENT_SUCCESS") {
              clearPaymentIntervalIds(timeoutIDs);
              handleCloseNestedPaymentModal();
              handleOpenPaymentSnackbar();
              setSnackbarStatusMessage("Payment successful");
              let temp = _.cloneDeep(loggedInUserDetails);
              // handleShowBannerPremium();
              temp["premium"] = true;
              setLoggedInUserDetails(temp);
            } else if (status.code !== "PAYMENT_PENDING") {
              clearPaymentIntervalIds(timeoutIDs);
              handleCloseNestedPaymentModal();
              handleOpenPaymentSnackbar();
              setSnackbarStatusMessage("Payment failed");
              
            }
          } catch (error){
            navigate('/');
            console.error("Error fetching payment status:", error);
          } finally {
          }
        },
        time,
        merchantTransactionId,
        loggedInUserDetails
      );

      timeoutIDs.push(timeoutID);
    }

    setIntervalIds(timeoutIDs.slice());
  };

  const navigateToPricing = useNavigate();

  const handlePayment = async (selectedUPIPayApp) => {
    try {
      setLoaderPayModal(true);

      let deviceOS;
      if (isIOS) {
        deviceOS = "IOS";
      }
      let response = await pay(
        loggedInUserDetails,
        deviceOS,
        selectedUPIPayApp
      );

      setLoaderPayModal(false);
      const merchantTransactionId = response.merchantTransactionId;
      reconcialationPaymentStatus(merchantTransactionId);
      setPayData(response);
    } catch (error) {
      setLoaderPayModal(false);
    }
  };

  const handlePaymentModalOpen = async (event, selectedUPIApp) => {
    // if logged in then only show the buy banner else prompt to login
    if (loggedInUserDetails.name) {
      if (isIOS && !showUPISelectScreen) {
        // open the UPI select screen if it isn't already opened and if it is IOS
        handleOpenUPISelectScreen();
      } else if (isIOS && showUPISelectScreen) {
        // handleCloseUPISelectScreen();
        handlePayment(selectedUPIApp);
        handleMobileMenuClose();
      } else {
        handlePayment();
        setPaymentModalOpen(true);
        handleMobileMenuClose();
      }
    } else {
      setLoginPrompt(true);
    } 
  };

  // useEffect(() => {
  //   let path = location.pathname.split("/")[1];

  //   if (path === "360-reader") {
  //     setSidebarData({ selectedIndex: 7 });
  //   } else if (path === "pyqexplorer") {
  //     setSidebarData({ selectedIndex: 8 });
  //   } else if (path === "upsc-ias-mock-interview") {
  //     setSidebarData({ selectedIndex: 3 });
  //   } else if (path === "calendar") {
  //     setSidebarData({ selectedIndex: 4 });
  //   } else if (path === "savedforlater") {
  //     setSidebarData({ selectedIndex: 5 });
  //   } else if (path === "pyqfasttrack") {
  //     setSidebarData({ selectedIndex: 6 });
  //   } else if (path === "upsc-dashboard") {
  //     setSidebarData({ selectedIndex: 0 });
  //   } else if (path === "upsc-mains-answer-evaluation" || path === "" || path === "home") {
  //     setSidebarData({ selectedIndex: 1 });
  //   } else if (path === "upsc-notes-zero") {
  //     setSidebarData({ selectedIndex: 9 });
  //   } else if (path === "upsc-mains-2023-essay-gs-questions-model-answers") {
  //     setSidebarData({ selectedIndex: 10 });
  //   }else if (path === "flash-card") {
  //     setSidebarData({ selectedIndex: 5 });
  //   }else if (path === "news") {
  //     setSidebarData({ selectedIndex: 5 });
  //   }
  //   if (localStorage.getItem("showBanner") === "true") {
  //     setBannerData({ showBanner: "true" });
  //   }
  // }, []);

  const handleLoginButton = () => {
    navigate("/login", { state: { from: window.location.pathname } });
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBottomNavigationMoreOpen = (event) => {
    setAnchorBottomNavigationMoreEl(event);
  };

  const handleMobileMenuClose = (event) => {
    setMobileMoreAnchorEl(null);
  };

  const handleBottomNavigationMoreClose = () => {
    setAnchorBottomNavigationMoreEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  

  const logout = () => {
    Cookies.remove("google_info");
    Cookies.remove("user_name");
    Cookies.remove("user_id");
    Cookies.remove("email");
    handleMenuClose();
    setLoggedInUserDetails(Object.assign({}));
    navigate("/login");
  };

  const toggleSidebarInMobile = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowSidebarInMobile(open);
  };

  const capitalizeFirstLetter = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str
  };

  const menuId = "primary-search-account-menu";
  const bottonNavigationMoreIconId = "bottom-navigation-more";
  const renderBottomNavigationMoreMenu = (
    <Menu
      sx={{ zIndex: "9999" }}
      anchorEl={anchorBottomNavigationMoreEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isBottomNavigationMoreOpen}
      onClose={handleBottomNavigationMoreClose}
    >
    <MenuItem
        onClick={() => {
          navigate("/upsc-mains-2024-essay-gs-questions-model-answers");
          handleBottomNavigationMoreClose();
        }}
      >
        <ListItemIcon>
        <ContentPasteIcon />
        </ListItemIcon>
        <ListItemText>Mains 2024</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/upsc-mains-2023-essay-gs-questions-model-answers");
          handleBottomNavigationMoreClose();
        }}
      >
        <ListItemIcon>
        <ContentPasteIcon />
        </ListItemIcon>
        <ListItemText>Mains 2023</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/upsc-notes-zero");
          handleBottomNavigationMoreClose();
        }}
      >
        <ListItemIcon>
          <BookIcon />
        </ListItemIcon>
        <ListItemText>Notes Zero</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/news");
          handleBottomNavigationMoreClose();
        }}
      >
        <ListItemIcon>
          <ArticleIcon
          // fontSize="small"
          />
        </ListItemIcon>
        <ListItemText>The Desi Explainer</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/mockinterview");
          handleBottomNavigationMoreClose();
        }}
      >
        <ListItemIcon>
          <CoPresentIcon
          // fontSize="small"
          />
        </ListItemIcon>
        <ListItemText>Mock Interview</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/pyqexplorer");
          handleBottomNavigationMoreClose();
        }}
      >
        <ListItemIcon>
          <MenuBookIcon
          // fontSize="small"
          />
        </ListItemIcon>
        <ListItemText>PYQ Explorer</ListItemText>
      </MenuItem>
      {/* <MenuItem
        onClick={() => {
          navigate("/pyqinterview", { replace: true });
          handleBottomNavigationMoreClose();
        }}>
        <ListItemIcon>
          <PsychologyIcon
          // fontSize="small"
          />
        </ListItemIcon>
        <ListItemText>PYQ Interview</ListItemText>
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          navigate("/pyq-fast-track");
          handleBottomNavigationMoreClose();
        }}
      >
        <ListItemIcon>
          <SpeedIcon
          // fontSize="small"
          />
        </ListItemIcon>
        <ListItemText>PYQ Fast Track</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/upsc-dashboard");
          handleBottomNavigationMoreClose();
        }}
      >
        <ListItemIcon>
          <DashboardIcon
          // fontSize="small"
          />
        </ListItemIcon>
        <ListItemText>UPSC Dashboard</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/savedforlater");
          handleBottomNavigationMoreClose();
        }}
      >
        <ListItemIcon>
          <BookmarkBorderIcon
          // fontSize="small"
          />
        </ListItemIcon>
        <ListItemText>Saved for later</ListItemText>
      </MenuItem>
    </Menu>
  );
  const renderMenu = (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileMenuClick}>
        <Typography textAlign="center">Profile</Typography>
      </MenuItem>
      {/* <MenuItem onClick={handlePlanMenuClick}>
        <Typography textAlign="center">Buy Plan</Typography>
      </MenuItem> */}
      <MenuItem onClick={logout}>
        <Typography textAlign="center">Logout</Typography>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
     
    
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          color="inherit"
          sx={{ marginRight: 1 }}
        >
          <CircularProgressWithLabel
            value={Number(100)}
            style={{ color: "#4cefae" }}
            loggedinuserdetails={loggedInUserDetails}
          />
        </IconButton>
      </MenuItem>
      <MenuItem>
        <Link
          href="/dashboard"
          rel="noreferrer"
          style={{ color: "black", fontSize: "1rem" }}
        >
          Dashboard
        </Link>
      </MenuItem>
      <MenuItem>
        <Link
          href={`${
            isMobile
              ? "mailto:support@collectorbabu.com"
              : "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@collectorbabu.com"
          }`}
          target="_blank"
          rel="noreferrer"
          style={{ color: "black", fontSize: "1rem" }}
        >
          Contact Us
        </Link>
      </MenuItem>
     
    </Menu>
  );

  return (
    <>
      <Box sx={{ display: "flex", maxWidth: "100%",overflowX: 'hidden' }}>
        {/* <AppBar position="fixed" sx={{ p: 1 }} style={{ background: "black" }}> */}
        <PaymentDialogParent
          paymentModalOpen={paymentModalOpen}
          handlePaymentModalOpen={handlePaymentModalOpen}
          payData={payData}
          loaderPayModal={loaderPayModal}
          handleCloseNestedPaymentModal={handleCloseNestedPaymentModal}
          handleOpenNestedPaymentModal={handleOpenNestedPaymentModal}
          handleCloseNestedPaymentModalOnly={handleCloseNestedPaymentModalOnly}
          paymentModalNestedOpen={paymentModalNestedOpen}
          showUPISelectScreen={showUPISelectScreen}
          handleOpenUPISelectScreen={handleOpenUPISelectScreen}
          handleCloseUPISelectScreen={handleCloseUPISelectScreen}
          setSelectedUPIApp={setSelectedUPIApp}
          selectedUPIApp={selectedUPIApp}
          clearPayIntervals={clearPayIntervals}
        />
        <BootstrapDialog
          onClose={handleLoginPromptClose}
          aria-labelledby="login prompt"
          open={openLoginPrompt}
        >
          <BootstrapDialogTitle
            id="login with your google account"
            onClose={handleLoginPromptClose}
          >
            Login for this feature
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              Login to get access to this feature and many more...
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleLoginButtonPrompt}>
              Login
            </Button>
          </DialogActions>
        </BootstrapDialog>
        <PaymentSnackbar
          snackbarStatusMessage={snackbarStatusMessage}
          open={showPaymentSnackbar}
          handleClosePaymentSnackbar={handleClosePaymentSnackbar}
        />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            p: Number(`${bannerData.showBanner === "true" ? 0 : 1}`),
          }}
          style={{
            background: "#fff",
            boxShadow: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
             
          {/* {(!loggedInUserDetails.premium && bannerData.showBanner === "true" ) &&
            <HeaderBanner handleRemoveBanner={handleRemoveBanner} />
          } */}
          
          <StyledAppBar elevation={0}>
  <StyledToolbar>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LogoImage
        onClick={() => navigate("/")}
        src="/images/mainLogo.png"
        alt="Company logo"
        loading="lazy"
      />
    </Box>

    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>

    {loggedInUserDetails.premium && (    
//     <ListItemIcon>
//     <WorkspacePremiumIcon />
// </ListItemIcon>
      <img className="w-8 h-8" src={premiumIcon} />
      )}
      <DashboardButton onClick={() => navigate("/dashboard")}>
        Dashboard
      </DashboardButton>
      {(loggedInUserDetails.plan!=="zenith_combo" && loggedInUserDetails.plan!=="zenith")&&(
      <BuyButton onClick={() => navigate("/pricing")}>
        Buy
      </BuyButton>
      )}

      {!loggedInUserDetails.user_id ? (
        <>
          <DashboardButton onClick={() => navigate("/profile")}>
            Login
          </DashboardButton>
          </>
      ) : (

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <img
        className="profile-image"
          // onClick={() => navigate("/profile")}
          onClick={handleMenuOpen}
          src={loggedInUserDetails.picture}
          alt="User profile"
        />
        {/* <IconButton
          aria-label="Open user menu"
          size="small"
          sx={{ padding: 0 }}
          onClick={handleMenuOpen}
        >
          <KeyboardArrowDownIcon />
        </IconButton> */}
      </Box>
    )}
    </Box>
  </StyledToolbar>
</StyledAppBar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <Drawer
          variant={`${matches ? "temporary" : "permanent"}`}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              borderColor: "#fff",
            },
          }}
          anchor="left"
          open={showSidebarInMobile}
          onClose={toggleSidebarInMobile(false)}
        >
          {/* <HeaderBanner /> */}
          <Toolbar />
          {!matches ? (
            <Box
              sx={{
                overflow: "auto",
                mt: Number(
                  `${bannerData.showBanner === "true" ? (matches ? 0 : 0) : 0}`
                ),
              }}
            >
              <List sx={{ mt: 2 }}>
                <ListItem
                  key={"AI Answer Review"}
                  disablePadding
                  sx={{ display: "block" }}
                  // secondaryAction={
                  //   <span>
                  //     <img src={trending_icon} width="40px" height="20px" alt="trending icon"></img>
                  //   </span>
                  // }
                >
                  <ListItemButton
                    data-text="AI Answer Review"
                    data-index={1}
                    onClick={handleDrawerIconNavigate(1)}
                    // selected={sidebarData["selectedIndex"] === 1}
                  >
                    <ListItemIcon data-text={"AI Answer Review"}>
                      <GradingRoundedIcon
                        fontSize="large"
                        // sx={{
                        //   color: `${
                        //     sidebarData["selectedIndex"] === 1
                        //       ? selectedIconColor
                        //       : unselectedIconColor
                        //   }`,
                        // }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"AI Answer Review"}
                      // sx={{
                      //   color: `${
                      //     sidebarData["selectedIndex"] === 1
                      //       ? selectedTabColor
                      //       : unselectedIconColor
                      //   }`,
                      // }}
                      data-text={"AI Answer Review"}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key={"360 Reader"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    data-text="360 Reader"
                    data-index={7}
                    onClick={handleDrawerIconNavigate(7)}
                    // selected={sidebarData["selectedIndex"] === 7}
                  >
                    <ListItemIcon data-text={"360 Reader"}>
                      <NewspaperIcon
                        fontSize="large"
                        // sx={{
                        //   color: `${
                        //     sidebarData["selectedIndex"] === 7
                        //       ? selectedIconColor
                        //       : unselectedIconColor
                        //   }`,
                        // }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"360 Reader"}
                      // sx={{
                      //   color: `${
                      //     sidebarData["selectedIndex"] === 7
                      //       ? selectedTabColor
                      //       : unselectedIconColor
                      //   }`,
                      // }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key={"mains 2024"}
                  disablePadding
                  sx={{ display: "block" }}
                  secondaryAction={
                    <span>
                      <img
                        src={new_icon}
                        width="40px"
                        height="20px"
                        alt="new icon"
                      ></img>
                    </span>
                  }
                  // secondaryAction={
                  //   <span>
                  //     <img src={trending_icon} width="40px" height="20px" alt="trending icon"></img>
                  //   </span>
                  // }
                >
                  <ListItemButton
                    data-text="mains 2024"
                    data-index={10}
                    onClick={handleDrawerIconNavigate(10)}
                    // selected={sidebarData["selectedIndex"] === 10}
                  >
                    <ListItemIcon data-text={"mains 2024"}>
                      <ContentPasteIcon
                        fontSize="large"
                        // sx={{
                        //   color: `${
                        //     sidebarData["selectedIndex"] === 10
                        //       ? selectedIconColor
                        //       : unselectedIconColor
                        //   }`,
                        // }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Mains 2024"}
                      // sx={{
                      //   color: `${
                      //     sidebarData["selectedIndex"] === 10
                      //       ? selectedTabColor
                      //       : unselectedIconColor
                      //   }`,
                      // }}
                      data-text={"mains 2024"}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key={"notes zero"}
                  disablePadding
                  sx={{ display: "block" }}
                  // secondaryAction={
                  //   <span>
                  //     <img
                  //       src={new_icon}
                  //       width="40px"
                  //       height="20px"
                  //       alt="new icon"
                  //     ></img>
                  //   </span>
                  // }
                >
                  <ListItemButton
                    data-text="notes zero"
                    data-index={9}
                    onClick={handleDrawerIconNavigate(9)}
                    // selected={sidebarData["selectedIndex"] === 9}
                  >
                    <ListItemIcon data-text={"notes zero"}>
                      <BookIcon
                        fontSize="large"
                        // sx={{
                        //   color: `${
                        //     sidebarData["selectedIndex"] === 9
                        //       ? selectedIconColor
                        //       : unselectedIconColor
                        //   }`,
                        // }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Notes Zero"}
                      // sx={{
                      //   color: `${
                      //     sidebarData["selectedIndex"] === 9
                      //       ? selectedTabColor
                      //       : unselectedIconColor
                      //   }`,
                      // }}
                      data-text={"notes zero"}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key={"PYQ Explorer"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    data-text="PYQ Explorer"
                    data-index={1}
                    onClick={handleDrawerIconNavigate(8)}
                    // selected={sidebarData["selectedIndex"] === 8}
                  >
                    <ListItemIcon data-text={"PYQ Explorer"}>
                      <MenuBookIcon
                        fontSize="large"
                        // sx={{
                        //   color: `${
                        //     sidebarData["selectedIndex"] === 8
                        //       ? selectedIconColor
                        //       : unselectedIconColor
                        //   }`,
                        // }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"PYQ Explorer"}
                      // sx={{
                      //   color: `${
                      //     sidebarData["selectedIndex"] === 8
                      //       ? selectedTabColor
                      //       : unselectedIconColor
                      //   }`,
                      // }}
                      data-text={"PYQ Explorer"}
                    />
                  </ListItemButton>
                </ListItem>
                {/* <ListItem key={"PYQ Interview"} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    data-text="PYQ Interview"
                    data-index={2}
                    onClick={handleDrawerIconNavigate(2)}
                    selected={sidebarData["selectedIndex"] === 2}>
                    <ListItemIcon data-text={"PYQ Interview"}>
                      <PsychologyIcon
                        fontSize="large"
                        sx={{
                          color: `${sidebarData["selectedIndex"] === 2 ? selectedIconColor : unselectedIconColor}`,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"PYQ Interview"}
                      sx={{
                        color: `${sidebarData["selectedIndex"] === 2 ? selectedTabColor : unselectedIconColor}`,
                      }}
                      data-text={"PYQ Interview"}
                    />
                  </ListItemButton>
                </ListItem> */}
                <ListItem
                  key={"The Desi Explainer"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    data-text="The Desi Explainer"
                    data-index={3}
                    onClick={handleDrawerIconNavigate(3)}
                    // selected={sidebarData["selectedIndex"] === 3}
                  >
                    <ListItemIcon data-text={"The Desi Explainer"}>
                      <ArticleIcon
                        fontSize="large"
                        // sx={{
                        //   color: `${
                        //     sidebarData["selectedIndex"] === 3
                        //       ? selectedIconColor
                        //       : unselectedIconColor
                        //   }`,
                        // }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"The Desi Explainer"}
                      // sx={{
                      //   color: `${
                      //     sidebarData["selectedIndex"] === 3
                      //       ? selectedTabColor
                      //       : unselectedIconColor
                      //   }`,
                      // }}
                      data-text={"The Desi Explainer"}
                    />
                  </ListItemButton>
                </ListItem>
                  <ListItem
                  key={"Mock Interview"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    data-text="Mock Interview"
                    data-index={3}
                    onClick={handleDrawerIconNavigate(3)}
                    // selected={sidebarData["selectedIndex"] === 3}
                  >
                    <ListItemIcon data-text={"Mock Interview"}>
                      <CoPresentIcon
                        fontSize="large"
                        // sx={{
                        //   color: `${
                        //     sidebarData["selectedIndex"] === 3
                        //       ? selectedIconColor
                        //       : unselectedIconColor
                        //   }`,
                        // }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Mock Interview"}
                      // sx={{
                      //   color: `${
                      //     sidebarData["selectedIndex"] === 3
                      //       ? selectedTabColor
                      //       : unselectedIconColor
                      //   }`,
                      // }}
                      data-text={"Mock Interview"}
                    />
                  </ListItemButton>
                </ListItem>
                {/* <ListItem key={"Calendar"} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    data-text="Calendar"
                                    data-index={4}
                                    onClick={handleDrawerIconNavigate(4)}
                                    selected={sidebarData["selectedIndex"] === 4}
                                >
                                    <ListItemIcon
                                        data-text={"Calendar"}
                                    >
                                        <CalendarTodayIcon fontSize="large" sx={{ color: `${sidebarData["selectedIndex"] === 4 ? selectedIconColor : unselectedIconColor}` }} />
                                    </ListItemIcon>
                                    <ListItemText primary={"Calendar"} sx={{ color: `${sidebarData["selectedIndex"] === 4 ? selectedTabColor : unselectedIconColor}` }} data-text={"Calendar"} />
                                </ListItemButton>
                            </ListItem> */}

                <ListItem
                  key={"PYQ Fast Track"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    data-text="PYQ Fast Track"
                    data-index={6}
                    onClick={handleDrawerIconNavigate(6)}
                    // selected={sidebarData["selectedIndex"] === 6}
                  >
                    <ListItemIcon data-text={"PYQ Fast Track"}>
                      <SpeedIcon
                        fontSize="large"
                        // sx={{
                        //   color: `${
                        //     sidebarData["selectedIndex"] === 6
                        //       ? selectedIconColor
                        //       : unselectedIconColor
                        //   }`,
                        // }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"PYQ Fast Track"}
                      // sx={{
                      //   color: `${
                      //     sidebarData["selectedIndex"] === 6
                      //       ? selectedTabColor
                      //       : unselectedIconColor
                      //   }`,
                      // }}
                      data-text={"PYQ Fast Track"}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  key={"UPSC-Dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    data-text="UPSC-Dashboard"
                    data-index={0}
                    onClick={handleDrawerIconNavigate(0)}
                    // selected={sidebarData["selectedIndex"] === 0}
                  >
                    <ListItemIcon data-text={"UPSC-Dashboard"}>
                      <DashboardIcon
                        fontSize="large"
                        // sx={{
                        //   color: `${
                        //     sidebarData["selectedIndex"] === 0
                        //       ? selectedIconColor
                        //       : unselectedIconColor
                        //   }`,
                        // }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"UPSC Dashboard"}
                      // sx={{
                      //   color: `${
                      //     sidebarData["selectedIndex"] === 0
                      //       ? selectedTabColor
                      //       : unselectedIconColor
                      //   }`,
                      // }}
                      data-text={"UPSC-Dashboard"}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key={"Saved for Later"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    data-text="Saved for Later"
                    data-index={5}
                    onClick={handleDrawerIconNavigate(5)}
                    // selected={sidebarData["selectedIndex"] === 5}
                  >
                    <ListItemIcon data-text={"Saved for Later"}>
                      <BookmarkBorderIcon
                        fontSize="large"
                        // sx={{
                        //   color: `${
                        //     sidebarData["selectedIndex"] === 5
                        //       ? selectedIconColor
                        //       : unselectedIconColor
                        //   }`,
                        // }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Saved for Late"}
                      // sx={{
                      //   color: `${
                      //     sidebarData["selectedIndex"] === 5
                      //       ? selectedTabColor
                      //       : unselectedIconColor
                      //   }`,
                      // }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          ) : null}
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width:"100%",
            mt: 8,
          }}
        >
        <BottomNavbar/>
    <Component handlePaymentModalOpen={handlePaymentModalOpen} />
          {/* {!loggedInUserDetails.premium && bannerData.showBanner === "true" ? (
  <div style={{ marginTop: "5rem" }}>
  </div>
) : (
  <>
  <Component handlePaymentModalOpen={handlePaymentModalOpen} />
  </>
)} */}


        </Box>
      </Box>
    </>
  );
};

export default LeftPane;
