import * as React from "react";
import "./index.css";
import UserContext from "../../UserContext";
import LoginPrompt from "../LoginPrompt";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import { isLoggedIn } from "../../utils";
import axios from "axios";
import { DAFOverviewContext } from "../../DAFOverviewContext";
import { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { FaLock } from "react-icons/fa";

export default function MockInterview() {
  const trackEvent = useAnalyticsEventTracker("AiAnswerReview Submit");
  const gaEventTracker = useAnalyticsEventTracker("upsc-ai-mock-interview");

  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const { setActiveStep, setUserInterviewId } = useContext(DAFOverviewContext);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [openLoginPrompt, setOpenLoginPrompt] = useState(false);

  useEffect(() => {
    wrapperIsLoggedIn();
    gaEventTracker(
      `${loggedInUserDetails?.name || "logged-out user"}/ai-mock`
    );
  }, []);

  const wrapperIsLoggedIn = async () => {
    try {
      const userData = await isLoggedIn();
      if (userData) {
        setLoggedInUserDetails(userData);
      }
    } catch (error) {
      console.error("Error checking login status:", error);
    }
  };

  const handleLoginPromptOpen = () => setOpenLoginPrompt(true);

  const handleLoginPromptClose = () => setOpenLoginPrompt(false);

  const authenticateUser = async () => {
    try {
      const response = await axios.post(
        `https://collectorbabu.com/api/interview/authenticate?user_id=${loggedInUserDetails.user_id}`
      );

      if (response.data.status==="authorized") {
        setUserAuthenticated(true);
      } else {
        setUserAuthenticated(false);
        console.error("Authentication failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error during user authentication:", error);
    }
  };

  useEffect(() => {
    if (loggedInUserDetails?.user_id) {
      authenticateUser();
    }
  }, [loggedInUserDetails]);

  const handleNext = async() => {
    if (!loggedInUserDetails?.user_id) {
      handleLoginPromptOpen();
      return;
    }
    setActiveStep(2);
  };

  return (
    <div className="interview-container">
      <div className="content-wrapper">
        <div className="header-section">
          <div className="title-wrapper">
            <h1 className="main-title">Mock Interview</h1>
            <p className="subtitle">
              Welcome to our AI-Enabled audio mock interview!
            </p>
          </div>
          <div className="category-badge">UPSC MAINS</div>
        </div>
        <div className="description-section">
          <p className="primary-text">
            Practice and improve your interview skills with our user-friendly
            tool that simulates a real interview experience. Please note that
            this is not a replacement for a real interview. Like other mock
            tests, it will only help you practice. Unlimited times.
          </p>
          <p className="secondary-text">
            Stay tuned for upcoming features like video interviews, recordings,
            and personalized feedback. But to move forward, we will need some ❤️ from your side!
          </p>
        </div>
        <Grid
          sx={{ marginTop: "30px" }}
          item
          xs={12}
          align="center"
          justify="center"
          alignItems="center"
        >
          <iframe
            width="560px"
            height="315"
            src="https://www.youtube.com/embed/wmdI6s_ltm0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Grid>
        {userAuthenticated ? (
          <button className="ai-mock-start-button" onClick={handleNext}>
            Start Interview
          </button>
        ) : (
          <button
            className="ai-mock-start-button"
            onClick={() =>
              (window.location.href =
                "mailto:support@collectorbabu.com?subject=Access%20Request&body=I%20would%20like%20to%20request%20access.")
            }
            tabIndex={0}
            aria-label="Start practicing mock interview"
          >
            <FaLock size={30} /> Email us to get access support@collectorbabu.com
          </button>
        )}
      </div>
      <LoginPrompt
        openLoginPrompt={openLoginPrompt}
        handleLoginPromptClose={handleLoginPromptClose}
      />
    </div>
  );
}
