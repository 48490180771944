import React, { useState, useEffect, useContext } from 'react';
import PdfView from './PdfView';
import axios from 'axios';
import { useMediaQuery, createTheme } from '@mui/material';
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

import { v4 as uuidv4 } from 'uuid';
import './index.css';
import SearchIcon from '@mui/icons-material/Search';
import UserContext from "../../UserContext";
import { isLoggedIn} from "../../utils";
import LoginPrompt from "../LoginPrompt";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AITable from '../AITable';
import AnswerTable from './AnswerTable';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Footer from "../Footer/Footer";
import LeftPane from "../LeftPane/LeftPane";
import OnScreenMenu from '../OnScreenMenu';

const QuestionTemplateContainer =()=>{
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const theme = createTheme();
  const gaEventTracker = useAnalyticsEventTracker("upsc-mains-answer-evaluation");
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    wrapperIsLoggedIn();
    gaEventTracker(`${loggedInUserDetails.name ? loggedInUserDetails.name : "logged-out user"}/dashboard`);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });

  
  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    } else {
    //   setOpenLoginPrompt(true);
    }
  }; 

  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  // });

  return (
    <>
      {isMobile ? (
        <>
        <Helmet>
          <title>Home - Collector Babu</title>
          <meta
            name="keywords"
            content="Daily quiz, daily current affairs - from Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS and more"
          />
          <meta
            name="description"
            content="Personalised Daily dashboard for UPSC IAS. AI-based learning. Online learning. Track progress in exam preparation. UPSC study materials. | Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS | The Hindu | The Indian Express | SansadTV"
          />

          <meta property="og:title" content="Daily Quiz, Daily Current Affairs" />
          <meta property="og:url" content="https://collectorbabu.com/" />
          <meta
            property="og:description"
            content="Practice and Track Daily Current Affairs, Previous Year Questions for UPSC IAS - Pre and Mains, State PCS and many other government exams"
          />
          <meta property="og:site_name" content="Collector Babu" />
        </Helmet>
        <LeftPane Component={QuestionTemplate} />
        <OnScreenMenu/>
    <Footer/>
        </>
      ):(  
        <>
        <Helmet>
          <title>Home - Collector Babu</title>
          <meta
            name="keywords"
            content="Daily quiz, daily current affairs - from Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS and more"
          />
          <meta
            name="description"
            content="Personalised Daily dashboard for UPSC IAS. AI-based learning. Online learning. Track progress in exam preparation. UPSC study materials. | Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS | The Hindu | The Indian Express | SansadTV"
          />

          <meta property="og:title" content="Daily Quiz, Daily Current Affairs" />
          <meta property="og:url" content="https://collectorbabu.com/" />
          <meta
            property="og:description"
            content="Practice and Track Daily Current Affairs, Previous Year Questions for UPSC IAS - Pre and Mains, State PCS and many other government exams"
          />
          <meta property="og:site_name" content="Collector Babu" />
        </Helmet>
        <Box
      sx={{
        display: 'flex',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100%',
      }}
    >

        <Navbar  />
        <Box
        sx={{
          height: '100vh',
          backgroundSize: 'cover',
        }}
      >
        <Sidebar />

        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <div style={{ marginTop: '75px',marginLeft:"90px", width:"93.5vw" }}> 
            <QuestionTemplate />
        <Footer />
          </div>
        </Box>
        <OnScreenMenu/>
        </Box>
        </Box>
        </>
      )}
    </>
  );
}


const QuestionTemplate = () => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const [questions, setQuestions] = useState(20);
  const [tableData, setTableData] = useState(Array.from({ length: questions }, () => ({
    present: true,
    question: '',
    pages: [3, 4],
    total_marks: 10
  })));
  const [pdfFile, setPdfFile] = useState(null);
  const [answerFile, setAnswerFile] = useState(null);
  const [pdfQuestionFile, setPdfQuestionFile] = useState(null);
  const [pdfFileName, setPdfFileName] = useState('');
  const [client, setClient] = useState('rau');
  const [subject, setSubject] = useState('GS');
  const [assignmentName, setAssignmentName] = useState('');
  const [fileHash, setFileHash] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchOption, setSearchOption] = useState('name'); 
  const [searchQuery, setSearchQuery] = useState('');
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openLoginPrompt, setOpenLoginPrompt] = useState(false);
  const [showQuestionPaper,setShowQuestionPaper] = useState(false);
  const [showAnswerPaper,setShowAnswerPaper] = useState(true);
  const [questionPaperUploaded, setQuestionPaperUploaded] = useState(false);
  const [uniqueId, setUniqueId] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedOption, setSelectedOption] = useState('GS');
  const [showOptional, setShowOptional] = useState(false);
  const [tableUploaded, setTableUploaded]=useState(false);
  const [answerSheetHash, setAnswerSheetHash]=useState("");
  const loggedInUserDetails_user_id=loggedInUserDetails.user_id;
  // const loggedInUserDetails_user_id="65d9c4300ac1441e5bf83bc4";


  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    } else {
      setOpenLoginPrompt(true);
    }
  };

  useEffect(() => {
    wrapperIsLoggedIn();
    
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleQuestionsChange = (e) => {
    e.preventDefault();
    const num = parseInt(e.target.value, 10);
    const newData = Array.from({ length: num }, (_, index) => {
      if (index < tableData.length) {
        return tableData[index];
      } else {
        return {
          present: false,
          question: '',
          pages: [1, 2],
          total_marks: 10
        };
      }
    });
    setQuestions(num);
    setTableData(newData);
  };

  const handleOptionalClick = () => {
    setShowOptional(true);
    setSelectedOption('');
  };

  const handleBackClick = () => {
    setShowOptional(false);
    setSelectedOption('');
  };


 
  const extractPdfDetails = async (e) => {
    e.preventDefault();
    uploadQuestionPaper(e);
    const file = e.target.files[0];
    try {
      const formData = new FormData();
      formData.append('question_paper', file);
  
      const response = await axios.post(
        'https://collectorbabu.com/api/template/extract_template_from_question_paper',
        formData,
        {
          params: {
            user_id: loggedInUserDetails_user_id,
            client: client
          },
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
  
      const filteredData = response.data.filter(item => item.question.trim() !== '');

      const formattedData = filteredData.map((item, index) => ({
        present: true,
        question: item.question,
        pages: [
          item.start_page !== undefined ? item.start_page : 3,
          item.end_page !== undefined ? item.end_page : 4,
        ],
        total_marks: item.total_marks,
      }));

      setQuestions(formattedData.length);
      setTableData(formattedData);
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarMessage("Template Successfully Extracted .");

  
      const hash = uuidv4();
      setFileHash(hash);
  
    } catch (error) {
      setSnackbarMessage("Error uploading the Template.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error('Error uploading question paper:', error);
    }
  };

  const uploadQuestionPaper = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setAnswerFile(file);
    setPdfFileName(file.name.split('.')[0]); 
  
    const reader = new FileReader();
    reader.onload = (event) => {
      setPdfFile(event.target.result); 
    };
    reader.readAsDataURL(file);
  };
  

  
  
  
  
  
  // correct one
  // const handlePageChange = (index, pageIndex, value) => {
  //   const updatedTableData = [...tableData];
  //   const newPageValue = parseInt(value, 10);
  
  //   if (isNaN(newPageValue) || newPageValue <= 0) {
  //     return;
  //   }
  
  //   if (pageIndex === 0) {
      
  //     updatedTableData[index].pages[0] = newPageValue;
  //     updatedTableData[index].pages[1] = Math.max(newPageValue + 1, updatedTableData[index].pages[1]);
  //   } else {
      
  //     updatedTableData[index].pages[1] = newPageValue;
  //   }
  
    
  //   for (let i = index + 1; i < updatedTableData.length; i++) {
  //     updatedTableData[i].pages[0] = updatedTableData[i - 1].pages[1] + 1;
  //     updatedTableData[i].pages[1] = updatedTableData[i].pages[0] + 1;
  //   }
  
  //   setTableData(updatedTableData);
  // };
 
  const handlePageChange = (index, pageIndex, value) => {
    const updatedTableData = [...tableData];
    const newPageValue = parseInt(value, 10);
  
    // if (isNaN(newPageValue) || newPageValue <= 0) {
    //   return;
    // }
  
    updatedTableData[index].pages[pageIndex] = newPageValue;
  
    setTableData(updatedTableData);
  };

  const handleIncrement = (index, type, pageIndex) => {
    const updatedTableData = [...tableData];
  
    if (type === 'pages') {
      if (pageIndex === 0) {
        updatedTableData[index].pages[0]++;
        if (updatedTableData[index].pages[0] >= updatedTableData[index].pages[1]) {
          updatedTableData[index].pages[1] = updatedTableData[index].pages[0] + 1;
        }
      } else {
        updatedTableData[index].pages[1]++;
      }
    }
  
    for (let i = index + 1; i < updatedTableData.length; i++) {
      const prevEndPage = updatedTableData[i - 1].pages[1];
      const pageCount = updatedTableData[i].pages[1] - updatedTableData[i].pages[0];
      updatedTableData[i].pages[0] = prevEndPage + 1;
      updatedTableData[i].pages[1] = updatedTableData[i].pages[0] + pageCount;
    }
  
    setTableData(updatedTableData);
  };
  
  const handleDecrement = (index, type, pageIndex) => {
    const updatedTableData = [...tableData];
  
    if (type === 'pages') {
      if (pageIndex === 0 && updatedTableData[index].pages[0] > 1) {
        updatedTableData[index].pages[0]--;
        if (updatedTableData[index].pages[0] > updatedTableData[index].pages[1]) {
          updatedTableData[index].pages[1] = updatedTableData[index].pages[0];
        }
      } else if (pageIndex === 1 && updatedTableData[index].pages[1] > updatedTableData[index].pages[0]) {
        updatedTableData[index].pages[1]--;
      }
    }
  
    for (let i = index + 1; i < updatedTableData.length; i++) {
      const prevEndPage = updatedTableData[i - 1].pages[1];
      const pageCount = updatedTableData[i].pages[1] - updatedTableData[i].pages[0];
      updatedTableData[i].pages[0] = prevEndPage + 1;
      updatedTableData[i].pages[1] = updatedTableData[i].pages[0] + pageCount;
    }
  
    for (let i = index + 1; i < updatedTableData.length; i++) {
      if (updatedTableData[i].pages[0] > 1) {
        const prevEndPage = updatedTableData[i - 1].pages[1];
        const pageCount = updatedTableData[i].pages[1] - updatedTableData[i].pages[0];
        updatedTableData[i].pages[0] = prevEndPage + 1;
        updatedTableData[i].pages[1] = updatedTableData[i].pages[0] + pageCount;
      }
    }
  
    setTableData(updatedTableData);
  };
  
  
  

  const handleMarkIncrement = (index, field, pageIndex = null) => {
    setTableData((prev) => {
      const newData = [...prev];
      if (pageIndex !== null) {
        newData[index][field][pageIndex] += 5;
      } else {
        newData[index][field] += 5;
      }
      return newData;
    });
  };

  
  const handleMarkDecrement = (index, field, pageIndex = null) => {
    setTableData((prev) => {
      const newData = [...prev];
      if (pageIndex !== null && newData[index][field][pageIndex] > 0) {
        newData[index][field][pageIndex] -= 5;
      } else if (pageIndex === null && newData[index][field] > 0) {
        newData[index][field] -= 5;
      }
      return newData;
    });
  };

  const handleTextChange = (index, field, value) => {
    setTableData((prev) => {
      const newData = [...prev];
      newData[index][field] = value;
      return newData;
    });
  };

  const handleNumChange = (index, field, value) => {
    setTableData((prev) => {
      const newData = [...prev];
      newData[index][field] = +value;
      return newData;
    });
  };
  

  
  const handlePresentChange = (index) => {
    setTableData((prev) => {
      const newData = [...prev];
      newData[index].present = !newData[index].present;  
      return newData;
    });
  };
  
  // const handleSelectAll = () => {
  //   setTableData((prev) => {
  //     const newData = prev.map((row) => ({ ...row, present: true }));
  //     return newData;
  //   });
  // };

  const handleSelectAll = () => {
    setTableData((prev) => {
      const allSelected = prev.every(row => row.present);
      const newData = prev.map((row) => ({ ...row, present: !allSelected }));
      return newData;
    });
  };



  // const handleDownload = () => {
  //   const filteredData = tableData
  //     .filter(row => row.present)
  //     .map(({ present, ...rest }) => rest);
  //   const json = JSON.stringify(filteredData, null, 2);
  //   const blob = new Blob([json], { type: 'application/json' });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.download = `${pdfFileName}_questions_template.json`; 
  //   link.click();
  //   URL.revokeObjectURL(url);
  // };

  const handleClientChange = (e) => {
    setClient(e.target.value);
  };

  const handleSearchQueryChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  
    if (query === '') {
      setSearchResults([]);
      return;
    }
    
  };
  
  
  

  const handleSaveTemplate = async () => {

    if(assignmentName===""){
      setSnackbarMessage("Assignment name required");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return
    }

    const hash = uuidv4();

    const filteredData = tableData.filter(item => item.question.trim() !== '');

    const updatedTableData = filteredData.map((item) => {
      if (!item.present) {
        return {
          ...item,
          pages: [999, 999],
          total_marks: 999,
        };
      }
      return item;
    });
      
    try {
  
      const payload = {
        user_id: loggedInUserDetails_user_id, 
        client,
        file_hash: hash,
        subject:selectedOption,
        assignment_name: assignmentName,
        template: updatedTableData,
      };
  
      const response = await axios.post(
        'https://collectorbabu.com/api/template/save_template',
        payload
      );
      setSnackbarMessage("Template Saved Successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Error Saving Template");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error('Error saving template:', error);
    }
  };


  const handleAssignmentNameChange = (e) => {
    setAssignmentName(e.target.value);
  };

  const handleSearch = async () => {
    try {
      const payload = {
        user_id: loggedInUserDetails_user_id,
        client,
        subject: selectedOption,
        [searchOption === 'name' ? 'assignment_name' : 'question']: searchQuery
      };
  
      const response = await axios.post(
        'https://collectorbabu.com/api/template/search_template',
        payload
      );
  
      if (Array.isArray(response.data)) {
        const filteredResults = response.data.filter(result => {
          const searchTerm = searchQuery.toLowerCase();
          if (searchOption === 'name') {
            return result.assignment_name.toLowerCase().includes(searchTerm);
          } else if (searchOption === 'question') {
            return result.template.some(template =>
              template.question.toLowerCase().includes(searchTerm)
            );
          }
          return false;
        });
      
        setSearchResults(filteredResults);
      }
      
  
    } catch (error) {
      console.error('Error searching template:', error);
    }
  };
  

  useEffect(() => {
    if (searchQuery) {
      handleSearch(); 
    }
  }, [searchQuery]);

  const handleAssignmentNameClick = (selectedResult) => {
    setSearchResults([]);
    const selectedTemplate = selectedResult.template.map(item => ({
      present: true,
      question: item.question,
      pages: item.pages,
      total_marks: item.total_marks,
    }));
    setTableData(selectedTemplate);
    setAssignmentName(selectedResult.assignment_name);
    setQuestions(selectedTemplate.length);
  };

  const handleQuestionClick=()=>{
    setShowQuestionPaper(true);
    setShowAnswerPaper(false);
    setQuestionPaperUploaded(false);
  }

  const handleAnswerClick=()=>{
    setShowAnswerPaper(true);
    setShowQuestionPaper(false);
  }

  const handleFileChange = async () => {
    try {
      if (answerFile) {
        const formData = new FormData();
        formData.append("mains_answer_sheet_pdf", answerFile);
        console.log(formData, "formData");
  
        let mainsAnswerSheetHash = "";
        let id = "";
  
        if (!tableUploaded) {
          const response = await axios.post(
            "https://collectorbabu.com/api/mains/mains_save_answer_sheet_pdf",
            formData,
            {
              params: {
                user_id: loggedInUserDetails_user_id,
                subject: selectedOption,
                use_vision: false,
              },
              headers: {
                accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          );
          mainsAnswerSheetHash = response.data.mains_answer_sheet_hash;
          id = response.data._id
        } else {
          mainsAnswerSheetHash = answerSheetHash; 
        }
  
        const updatedTableData = tableData.map((item) => {
          if (!item.present) {
            return {
              ...item,
              pages: [999, 999],
              total_marks: 999,
            };
          }
          return item;
        });
  
        const questionPageMappingData = {
          user_id: loggedInUserDetails_user_id,
          id:uniqueId,
          mains_answer_sheet_hash: mainsAnswerSheetHash,
          question_page_mapping: updatedTableData,
        };
  
        const secondResponse = await axios.post(
          "https://collectorbabu.com/api/mains/mains_save_question_page_mapping",
          questionPageMappingData,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
  
        if (secondResponse.status === 200) {
          setSnackbarMessage("Your pdf has been successfully uploaded.");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage("Error uploading the pdf.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      }
    } catch (error) {
      console.error("Error uploading PDF file:", error);
      setSnackbarMessage("Error uploading the pdf.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  
  
  const handleSetId=(id)=>{
    setUniqueId(id)
  }
  
  const handleSetPdf = async (pdfUrl, pdfName, pdfHash) => {
    setTableUploaded(true);
    setPdfFile(pdfUrl);
    setAnswerSheetHash(pdfHash);
    setPdfFileName(pdfName);
    try {
        const response = await fetch(pdfUrl);
        const blob = await response.blob();
        const newFile = new File([blob], pdfName, { type: 'application/pdf' });
        console.log(newFile);
        setAnswerFile( newFile ); 
    } catch (error) {
        console.error('Error fetching PDF:', error);
    }
};


  
  

  return (
    <>
{loggedInUserDetails_user_id && (

    <div className="container-temp">
    <div className="answer-sheet-container w-[100%] h-[40%] justify-between">
    <div className='h-[100%]'>
    <AnswerTable setId={handleSetId} setPdf={handleSetPdf} loggedInUserDetails={loggedInUserDetails}/>
    </div>
    <div className='upload-sections-question w-[50%] h-[100%]'>
    <div className="client-search-container">
      <div className="client-select-wrapper">
        <label className="client-label">Select Client: </label>
        <select value={client} onChange={handleClientChange} className="client-select">
          <option value="rau">RAU</option>
          <option value="kpias">KPIAS</option>
          <option value="narayanagroup">NARAYANA GROUP</option>
          <option value="kalamias">KALAM IAS</option>
          <option value="studyiq">StudyIQ</option>
        </select>
      </div>
      <div className="search-container">
  <input
    type="text"
    value={searchQuery}
    onChange={handleSearchQueryChange}
    placeholder={`Search ${searchOption === 'name' ? 'template by name' : 'template by question'}...`}
    className="search-input"
  />
  <button onClick={handleSearch} className="search-btn">
    <SearchIcon size={30} />
  </button>
</div>

      {/* {loading && <div className="loading-spinner"></div>} */}
      {searchResults.length > 0 && (
  <ul className="search-results-dropdown">
    {searchResults.map((result, index) => (
      <li key={index} className="search-result-item" onClick={() => handleAssignmentNameClick(result)}>
        {result.assignment_name}
      </li>
    ))}
  </ul>
)}

<div className="form-container-temp">
<div className="options-list-container-b2c">
      {!showOptional ? (
        <Grid container spacing={2}>
          <Grid item>
            <Button
              style={{
                backgroundColor: selectedOption === 'GS' ? 'blue' : 'white',
                color: selectedOption === 'GS' ? 'white' : 'black',
              }}
              variant="contained"
              onClick={() => setSelectedOption('GS')}
            >
              GS 1/2/3
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: selectedOption === 'GS4' ? 'blue' : 'white',
                color: selectedOption === 'GS4' ? 'white' : 'black',
              }}
              variant="contained"
              onClick={() => setSelectedOption('GS4')}
            >
              GS4
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: selectedOption === 'ESSAY' ? 'blue' : 'white',
                color: selectedOption === 'ESSAY' ? 'white' : 'black',
              }}
              variant="contained"
              onClick={() => setSelectedOption('ESSAY')}
            >
              Essay
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{ backgroundColor: 'white', color: 'black' }}
              variant="contained"
              onClick={handleOptionalClick}
            >
              Optional
            </Button>
          </Grid>
        </Grid>
      ) : (
        <div>
          <Button
            style={{ backgroundColor: 'white', color: 'black' }}
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={handleBackClick}
          >
            Back
          </Button>
          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            <Grid item>
              <Button
                style={{
                  backgroundColor: selectedOption === 'SOCIOLOGY' ? 'blue' : 'white',
                  color: selectedOption === 'SOCIOLOGY' ? 'white' : 'black',
                }}
                variant="contained"
                onClick={() => setSelectedOption('SOCIOLOGY')}
              >
                Sociology
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: selectedOption === 'PSIR' ? 'blue' : 'white',
                  color: selectedOption === 'PSIR' ? 'white' : 'black',
                }}
                variant="contained"
                onClick={() => setSelectedOption('PSIR')}
              >
                PSIR
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: selectedOption === 'ANTHROPOLOGY' ? 'blue' : 'white',
                  color: selectedOption === 'ANTHROPOLOGY' ? 'white' : 'black',
                }}
                variant="contained"
                onClick={() => setSelectedOption('ANTHROPOLOGY')}
              >
                Anthropology
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: selectedOption === 'TELANGANAMOVEMENT' ? 'blue' : 'white',
                  color: selectedOption === 'TELANGANAMOVEMENT' ? 'white' : 'black',
                }}
                variant="contained"
                onClick={() => setSelectedOption('TELANGANAMOVEMENT')}
              >
                Telanganamovement
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: selectedOption === 'LAW' ? 'blue' : 'white',
                  color: selectedOption === 'LAW' ? 'white' : 'black',
                }}
                variant="contained"
                onClick={() => setSelectedOption('LAW')}
              >
                Law
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: selectedOption === 'HISTORY' ? 'blue' : 'white',
                  color: selectedOption === 'HISTORY' ? 'white' : 'black',
                }}
                variant="contained"
                onClick={() => setSelectedOption('HISTORY')}
              >
                History
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: selectedOption === 'PSYCHOLOGY' ? 'blue' : 'white',
                  color: selectedOption === 'PSYCHOLOGY' ? 'white' : 'black',
                }}
                variant="contained"
                onClick={() => setSelectedOption('PSYCHOLOGY')}
              >
                Psychology
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: selectedOption === 'PUBAD' ? 'blue' : 'white',
                  color: selectedOption === 'PUBAD' ? 'white' : 'black',
                }}
                variant="contained"
                onClick={() => setSelectedOption('PUBAD')}
              >
                PUBAD
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: selectedOption === 'PHILOSOPHY' ? 'blue' : 'white',
                  color: selectedOption === 'PHILOSOPHY' ? 'white' : 'black',
                }}
                variant="contained"
                onClick={() => setSelectedOption('PHILOSOPHY')}
              >
                PHILOSOPHY
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: selectedOption === 'GEOGRAPHY' ? 'blue' : 'white',
                  color: selectedOption === 'GEOGRAPHY' ? 'white' : 'black',
                }}
                variant="contained"
                onClick={() => setSelectedOption('GEOGRAPHY')}
              >
                GEOGRAPHY
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
    {showQuestionPaper && (
      
  <div className="input-group-temp">
    <label>Assignment Name: <span className="required-asterisk-temp">*</span></label>
    <input 
      type="text" 
      value={assignmentName} 
      placeholder='Enter your assignment name'
      onChange={handleAssignmentNameChange} 
      className="assignment-name-input-temp"
      required
    />
  </div>
    )}
</div>

    </div>
      <div className="header-temp">
      <div>
  <label 
    className="label-temp" 
    tag="label-temp-tag"
  >
    Enter Number of Questions: 
  </label>
  <input 
    type="number" 
    value={questions} 
    onChange={handleQuestionsChange} 
    className="input-number-temp" 
    tag="input-number-temp-tag"
  />
</div>

<div className="upload-container-temp">
{showAnswerPaper && (
  <div className="upload-section">
    <label className="upload-label">Upload Answer Sheet: </label>
    <input 
      type="file" 
      onChange={(e) => uploadQuestionPaper(e)} 
      className="input-file"
    />
  </div>
)}
  {showQuestionPaper && (
  <div className="upload-section">
    <label className="upload-label">Upload Question Paper: </label>
    <input 
      type="file" 
      onChange={(e) => extractPdfDetails(e)} 
      className="input-file"
    />
  </div>
  )}
</div>

        <div>
          <div className="toggle-button-container">
          <button
        className={`toggle-button ${showQuestionPaper === true ? 'active' : ''}`}
        onClick={handleQuestionClick}
      >
      <div className="pdf-button-b2c">
        <span>Question Paper</span>
        </div>
      </button>
      
        <button
        className={`toggle-button ${showAnswerPaper === true ? 'active' : ''}`}
        onClick={handleAnswerClick}
      >
        Answer Sheet
      </button>
</div>
</div>
        </div>
      </div>
      </div>
      <div className="content-temp">
      {pdfFile && (
            <div className="pdf-preview-temp">
              <PdfView pdfName={pdfFileName} url={pdfFile} />
            </div>
          )}
          {/* {questionPaperUploaded ? (
        <div className="pdf-preview-temp">
          <PdfView pdfName={pdfFileName} url={pdfQuestionFile} />
        </div>
      ) : (
        <div className="upload-container">
          <input type="file" onChange={handleQuestionUpload} />
          <button onClick={uploadQuestionPaper}>Upload Question Paper</button>
        </div>
      )} */}
          <div className="table-container-temp">
            <table className="table-temp">
              <thead>
                <tr>
                  <th className="th-temp">S.No</th>
                  {/* <th className="th-temp">Present</th> */}
                  <th className="th-temp">
                      <button onClick={handleSelectAll} className="select-btn-temp">
                        {/* {tableData.every(row => row.present) ? 'Unselect All' : '✓ All'} */}
                        ⬜/✓
                      </button>
                    </th>
                  <th className="th-temp ">Question</th>
                  <th className="th-temp w-[10%]">Start Page</th>
                  <th className="th-temp w-[10%]">End Page</th>
                  <th className="th-temp w-[10%]">Marks</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td className="td-temp">{index + 1}</td>
                    <td className="td-temp">
                      <input 
                        type="checkbox" 
                        checked={row.present} 
                        onChange={() => handlePresentChange(index)} 
                      />
                    </td>
                    <td className="td-temp">
                      <textarea 
                      className="textarea-temp"
                        value={row.question} 
                        onChange={(e) => handleTextChange(index, 'question', e.target.value)} 
                        rows="2"
                        cols="20"
                      />
                    </td>
                    <td className="td-temp">
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div 
                          className="increment-decrement-btn" 
                          onClick={() => handleDecrement(index, 'pages', 0)}
                        >
                          -
                        </div>
                        <input 
                          type="number" 
                          value={row.pages[0]} 
                          onChange={(e) => handlePageChange(index, 0, e.target.value)} 
                        />
                        <div 
                          className="increment-decrement-btn" 
                          onClick={() => handleIncrement(index, 'pages', 0)}
                        >
                          +
                        </div>
                      </div>
                    </td>
                    <td className="td-temp">
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div 
                          className="increment-decrement-btn" 
                          onClick={() => handleDecrement(index, 'pages', 1)}
                        >
                          -
                        </div>
                        <input 
                          type="number" 
                          value={row.pages[1]} 
                          onChange={(e) => handlePageChange(index, 1, e.target.value)} 
                        />
                        <div 
                          className="increment-decrement-btn" 
                          onClick={() => handleIncrement(index, 'pages', 1)}
                        >
                          +
                        </div>
                      </div>
                    </td>
                    <td className="td-temp">
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div 
                          className="increment-decrement-btn" 
                          onClick={() => handleMarkDecrement(index, 'total_marks')}
                        >
                          -
                        </div>
                        <input 
                          type="number" 
                          value={row.total_marks} 
                          onChange={(e) => handleNumChange(index, 'total_marks', e.target.value)} 
                        />
                        <div 
                          className="increment-decrement-btn" 
                          onClick={() => handleMarkIncrement(index, 'total_marks')}
                        >
                          +
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
  

<div className="download-save-btn-temp">
        {showAnswerPaper && <button onClick={handleFileChange} className="download-btn-temp">Submit Answer Paper</button>}
        {showQuestionPaper && <button onClick={handleSaveTemplate} className="download-btn-temp">Save Template</button>}
      </div>
      </div>
)}
<Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
</>

  );
};

export default QuestionTemplateContainer;