import React,{ useState, useEffect, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import UserContext from "../../UserContext";
import OnScreenMenu from '../OnScreenMenu';
import { useMediaQuery, createTheme } from '@mui/material';
import LeftPane from "../LeftPane/LeftPane";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import DOMPurify from 'dompurify';
import { useLocation } from "react-router-dom";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import {isLoggedIn} from "../../utils";
import ReactGA from "react-ga4";
import Footer from "../Footer/Footer";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SamplePdf from './SamplePdf.pdf';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const MainsAnswerDashboard =()=>{
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const theme = createTheme();
  const gaEventTracker = useAnalyticsEventTracker("upsc-mains-answer-evaluation-answer");
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    wrapperIsLoggedIn();
    gaEventTracker(`${loggedInUserDetails.name ? loggedInUserDetails.name : "logged-out user"}/dashboard`);
  }, []);

  
  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    } else {
    //   setOpenLoginPrompt(true);
    }
  }; 

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });

  return (
    <>
      {isMobile ? (
        <>
        <LeftPane Component={MainsAnswer} />
        <OnScreenMenu/>
    <Footer/>
        </>
      ):(  
        <>
        <Box
      sx={{
        display: 'flex',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100%',
      }}
    >

        <Navbar  />
        <Box
        sx={{
          height: '100vh',
          backgroundSize: 'cover',
        }}
      >
        <Sidebar />

        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <div style={{ marginTop: '75px',marginLeft:"90px", width:"101%"}}> 
            <MainsAnswer />
        <Footer />
          </div>
        </Box>
        <OnScreenMenu/>
        </Box>
        </Box>
        </>
      )}
    </>
  );
}


const MainsAnswer = ({ activeQuestionIndex, mainsQuestion, showManualQuestionUpload, modelAnswer }) => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const location = useLocation();
  const { mainsAnswer } = location.state || {};
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  const [openSamplePdf, setOpenSamplePdf] = React.useState(false);

  const handleClickOpenSamplePdf = () => {
    setOpenSamplePdf(true);
  };
  const handleCloseSamplePdf = () => {
    setOpenSamplePdf(false);
  };

  const [numPages, setNumPages] = useState(null);
  const [pageWidth, setPageWidth] = useState(0);
  const containerRef = useRef(null);

  // Function to handle successful document loading
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Set page width based on screen size
  useEffect(() => {
    const updatePageWidth = () => {
      if (window.innerWidth < 768) {
        setPageWidth(400); // Mobile view
      } else {
        setPageWidth(1000); // Medium and large screens
      }
    };

    // Set initial width
    updatePageWidth();

    // Listen for window resize events
    window.addEventListener('resize', updatePageWidth);

    return () => window.removeEventListener('resize', updatePageWidth);
  }, []);
  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  const answerSections = Array.isArray(mainsAnswer) ? mainsAnswer : [];
useEffect(()=>{
  if (
    loggedInUserDetails.plan !== "trial" &&
      loggedInUserDetails.plan !== "peak" &&
      loggedInUserDetails.plan !== "zenith" &&
      loggedInUserDetails.plan !== "zenith_combo" &&
      loggedInUserDetails.plan !== "zenith_pro" 
) {
    setOpenSamplePdf(true);
    console.log("Dialog state:", openSamplePdf);
  }    //comment2    
},[])
  return (
<>
    <Card sx={{ minWidth: 275, maxWidth: 750, height: 'auto', margin: '10px auto' }}>
      <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src="/images/CBLogoAnswerE.png"
          alt="AI Review Logo"
          style={{ width: 70, aspectRatio: '1.01', objectFit: 'contain', marginBottom: '20px' }}
        />
        </Box>
        <Box
        component="header"
        sx={{
          borderRadius: "8px 8px 0 0",
          backgroundColor: "rgba(228, 255, 224, 1)",
          // border: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottomWidth: 1,
          maxWidth: 750,
          overflow: "hidden",
          padding: "6px 16px",
          "@media (max-width: 991px)": {
            paddingRight: "20px",
          },
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: 300,
            lineHeight: 1,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          CollectorBabu AI Answer Review
        </Typography>
      </Box>
        <div style={{ padding: '20px 30px' }}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{  p: 2, whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: sanitizeHTML(mainsAnswer) }}
          />
          {mainsAnswer && (
            <>
             
            </>
          )}
          {answerSections.map((section, index) => (
            <section key={index} style={{ padding: '10px 0' }}>
              <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
                {section.title}
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                {section.content}
              </Typography>
            </section>
          ))}
        </div>
      </CardContent>
    </Card>
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <button style={{ marginTop: '20px', marginBottom: '20px', padding: '10px 20px', backgroundColor: '#23B279', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', fontSize: '16px', fontWeight: 'bold' }} onClick={() => window.history.back()}>Back</button>
    </Box>
    <BootstrapDialog
      onClose={handleCloseSamplePdf}
      aria-labelledby="customized-dialog-title"
      open={openSamplePdf}
      fullWidth
      PaperProps={{
        sx: {
          width: {
            xs: '100%',  // Full width on mobile
            md: '70%'    // 70% width on desktop
          },
          maxWidth: 'none !important',
          height: {
            xs: 'calc(100vh - 32px)', // Almost full height on mobile
            md: '85vh'                // 85% height on desktop
          },
          m: {
            xs: '16px 0',  // Only vertical margin on mobile
            md: '16px auto' // All around margin on desktop
          },
          position: 'relative',
          overflow: 'hidden'
        }
      }}
    >
      <div className="flex flex-col h-full w-full">
        <DialogTitle 
          sx={{ 
            m: 0, 
            p: 2,
            flexShrink: 0  // Prevent title from shrinking
          }} 
          id="customized-dialog-title"
        >
          Try Our Zenith Plan for Full evaluation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseSamplePdf}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent 
          dividers
          sx={{
            p: {
              xs: 1,  // Less padding on mobile
              md: 2
            },
            flexGrow: 1,
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {/* <div className="w-full h-full relative">
            <iframe
              src={SamplePdf}
              title="PDF Viewer"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
                overflow: 'auto'
              }}
            />
          </div> */}
          <div className="h-full overflow-y-auto w-full flex justify-center">
          <Document
  file={SamplePdf}
  onLoadSuccess={onDocumentLoadSuccess}
  loading={
    <div className="flex items-center justify-center w-full h-32">
      Loading PDF...
    </div>
  }
  error={
    <div className="flex items-center justify-center w-full h-32 text-red-500">
      Error loading PDF. Please try again.
    </div>
  }
>
  {Array.from({ length: numPages }, (_, index) => (
    <div key={`page_${index + 1}`} className="mb-4">
      <Page
        pageNumber={index + 1}
        width={pageWidth}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        loading={
          <div className="h-96 flex items-center justify-center">
            Loading page...
          </div>
        }
      />
    </div>
  ))}
</Document>
    </div>
        </DialogContent>
      </div>
    </BootstrapDialog>
    </>
  );
};

export default MainsAnswerDashboard;

