import React, { useState, useContext } from "react";
import axios from "axios";
import './index.css';
import { useNavigate } from 'react-router-dom';
import UserContext from "../../UserContext";
import { DAFOverviewContext } from "../../DAFOverviewContext";
import { FilePlus, Trash } from "@phosphor-icons/react";
import { CloudArrowUp } from "@phosphor-icons/react";
import { BallTriangle } from 'react-loader-spinner';

export default function UploadDaf() {
  const navigate = useNavigate();
  const { dafOverview ,setDafOverview, setActiveStep, setMockInterviewDAFQuestions, setUserInterviewId } = useContext(DAFOverviewContext);
  const [loggedInUserDetails] = useContext(UserContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [loadingDAFUpload, setLoadingDAFUpload] = useState(false);
  const [dafUploadError, setDafUploadError] = useState(false);
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [aiQuestionsData, setAiQuestionsData] = useState([]);

  // console.log("selectedTopics", selectedTopics);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setUploadedFileName(file.name);

    // Automatically trigger API call after file selection
    if (file) {
      await handleFileUpload(file);
    }
  };

  const handleFileUpload = async (file) => {
    if (!file) {
      setUploadStatus("Please select a file before uploading.");
      return;
    }

    try {
      const form = new FormData();
      form.append("daf_file", file);

      setLoadingDAFUpload(true);
      setUploadStatus("Uploading...");

      const response = await axios.post(
        "https://collectorbabu.com/api/interview/daf_analysis",
        form,
        {
          params: {
            user_id: loggedInUserDetails.user_id,
          },
          headers: {
            accept: "application/json",
            token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const dafOverviewData = response.data;
      const dafQuestionsTopics = dafOverviewData.daf_questions.exact_match || [];
      const aiQuestionsTopics = (dafOverviewData.daf_questions.ai_questions || []).map(
        (q) => q.topic
      );

      
      const combinedTopics = Array.from(
        new Set([...dafQuestionsTopics, ...aiQuestionsTopics])
      );
      setDafOverview(dafOverviewData);
      setQuestions(dafOverviewData.daf_questions.questions || []);
      setAiQuestionsData(dafOverviewData.daf_questions.ai_questions || []);
      setTopics(combinedTopics);
      setSelectedTopics(combinedTopics);
      setUploadStatus("Upload successful!");
      setLoadingDAFUpload(false);
    } catch (error) {
      console.error(error);
      setLoadingDAFUpload(false);
      setDafUploadError(true);
      setUploadStatus("Upload failed. Please try again.");
    }
  };

  const handleTopicChange = (topic) => {
    setSelectedTopics((prevTopics) =>
      prevTopics.includes(topic)
        ? prevTopics.filter((t) => t !== topic)
        : [...prevTopics, topic]
    );
  };

  const startInterview = async () => {
    try {
      const response = await axios.post(
        `https://collectorbabu.com/api/interview/start_interview?user_id=${loggedInUserDetails.user_id}`
      );
      setUserInterviewId(response.data.interview_id);
    } catch (error) {
      console.error("Error starting interview:", error);
    }
  }

  const generateQuestions = async () => {
    if (!selectedTopics.length) {
      setUploadStatus("Please select at least one topic.");
      return;
    }

    // const allQuestions = [];
    const filteredQuestions = questions.filter((q) =>
      selectedTopics.includes(q.topic)
    );

    const aiQuestionsFinal = aiQuestionsData.filter((q) =>
      selectedTopics.includes(q.topic)
    );





    const combinedQuestions = [...filteredQuestions, ...aiQuestionsFinal]
      .map((q) => q.question)
      .sort(() => 0.5 - Math.random())
      .slice(0, 30);

      await startInterview();


    setMockInterviewDAFQuestions(combinedQuestions);
    // setActiveStep(3);
    navigate("/ai-interview", { state: { userId: loggedInUserDetails.user_id } });
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
    setUploadedFileName("");
    setUploadStatus("");
  };

  return (
    <div className="upload-container-ai-mock">
      <div className="header-section">
        <div className="w-100 flex justify-center">
          <div className="file-icon-plus-container">
            <FilePlus color="#1F5B43" size={36} />
          </div>
        </div>
        <div className="header-title">Upload Your DAF</div>
      </div>

      {uploadedFileName ? (
        <div className="uploaded-file-container">
          <div className="file-details">
            <span>{uploadedFileName}</span>
            <Trash
              size={24}
              color="red"
              className="delete-icon"
              onClick={handleFileDelete}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      ) : (
        <form className="upload-field" onSubmit={(e) => e.preventDefault()}>
          <CloudArrowUp size={32} />
          <div className="upload-content">
            <div className="upload-description">
              <div className="upload-text">Select a file or drag and drop here</div>
              <div className="file-types">JPG, PNG or PDF, file</div>
            </div>
            <label htmlFor="file-upload" className="visually-hidden">
              Upload file
            </label>
            <button
              type="button"
              className="select-button-ai-mock"
              onClick={() => document.getElementById("file-upload").click()}
            >
              Select File
            </button>
            <input
              id="file-upload"
              type="file"
              accept=".jpg,.png,.pdf"
              className="visually-hidden"
              onChange={handleFileChange}
            />
          </div>
        </form>
      )}

      {topics.length > 0 && (
        <div className="topics-container">
          <div className="filter-title">Select Topics:</div>
          <div className="topics-list">
            {topics.map((topic) => (
              <label key={topic} className="topic-label">
                <input
                  type="checkbox"
                  checked={selectedTopics.includes(topic)}
                  onChange={() => handleTopicChange(topic)}
                />
                {topic}
              </label>
            ))}
          </div>
        </div>
      )}

      {selectedTopics.length !== 0 && (
        

        <button
          className="details-button"
          onClick={generateQuestions}
          disabled={loadingDAFUpload}
        >
          Start Interview
        </button>

      )}


      {uploadStatus && (
        <div className="upload-status">
          {loadingDAFUpload ? (
            <BallTriangle height={100} width={100} color="#4fa94d" visible={true} />
          ) : (
            <span>{uploadStatus}</span>
          )}
        </div>
      )}
    </div>
  );
}
